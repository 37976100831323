<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        style="display:flex"
      >
        <logo class="d-none d-lg-block" />

      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center "
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            v-if="mediaType === 'IMAGE'"
            fluid
            :src="mediaUrl"
            alt="Login V2"
          />
          <video
            v-if="mediaType === 'VIDEO'"
            autoplay
            loop
            muted
            style="height: 100vh;"
            :src="mediaUrl"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="auth-bg px-2 px-3"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="mx-auto"
        >
          <div class="mt-1 d-flex flex-row-reverse justify-content-between w-100">
            <dark-Toggler class=" d-block" />
          </div>
        </b-col>
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto "
        >
          <b-row
            v-if="registerStep === 1"
            align-h="center"
            align-v="center"
            class="mb-2"
          >
            <b-col cols="auto">
              <logo class="d-lg-block" />
            </b-col>
          </b-row>

          <b-card-title
            class="mb-1 text-center"
            title-tag="h2"
          >
            <span class="font-weight-bold">{{ $t('crypto_Cabin_Title', { brand_name: getBrandName() }) }}</span>
          </b-card-title>
          <b-card-text class="mb-2 text-center">
            {{ $t('account_registration') }}
          </b-card-text>

          <b-row
            v-if="registerStep === 2"
            class="mb-2"
          >
            <b-col>
              <b-button
                variant="outline-primary"
                block
                disabled
              >
                <b-row
                  align-h="between"
                  class="w-100"
                >
                  <b-col cols="auto">
                    {{ $t('seldected_register_type') }}
                  </b-col>
                  <b-col cols="auto">
                    {{ isLegal ? $t('legal') : $t('real') }}
                  </b-col>
                </b-row>
              </b-button>
            </b-col>
            <b-col cols="auto">
              <b-button
                variant="primary"
                @click="changeStep(1)"
              >
                <span v-if="$store.getters['app/currentBreakPoint'] === 'xs'">X</span>
                <span v-else>{{ $t('change') }}</span>
              </b-button>
            </b-col>
          </b-row>
          <div v-if="registerStep === 2">
            {{ isLegal ? $t('please_register_legal') + ' ' + '(' + $t('register-latin-letters') + ')' :
              $t('please_register') + ' ' + '(' + $t('register-latin-letters') + ')' }}
          </div>
          <div>
            <!-- form -->
            <validation-observer
              ref="registerForm"
              #default="{ invalid }"
            >
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="submit"
              >
                <div
                  v-if="registerStep === 1"
                  class="mb-3"
                >
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label-for="default-language"
                        :label="$t('choose_default_language')"
                      >
                        <v-select
                          v-model="language"
                          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="options"
                          :placeholder="$t('choose_default_language')"
                          autocomplete="nop"
                          @input="changeLang(language)"
                        >
                          <template
                            slot="option"
                            slot-scope="option"
                          >
                            <img
                              :src="option.img"
                              class="language-image"
                            >
                            {{ option.name }}
                          </template>
                        </v-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="my-2">
                    {{ $t('register_type_description') }}
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-button
                        class="mb-1"
                        variant="primary"
                        block
                        type="button"
                        @click="setLegal(false)"
                      >
                        {{ $t('real_register') }}
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <b-button
                        variant="primary"
                        block
                        type="button"
                        @click="setLegal(true)"
                      >
                        {{ $t('legal_register') }}
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
                <div v-else-if="registerStep === 2">
                  <b-row>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <b-form-group
                        :label="isLegal ? $t('firstname_legal') : $t('firstname')"
                        label-for="first_name"
                        style="margin-bottom: 26px;"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('firstname')"
                          vid="first_name"
                          rules="required|notPersian"
                        >
                          <b-form-input
                            id="first_name"
                            v-model="form.first_name"
                            :name="$t('firstname')"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('firstname')"
                            @keypress="AlphaNumericAndEspecialCharacters($event)"
                          />
                          <small class="text-danger position-absolute">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <b-form-group
                        :label="isLegal ? $t('lastname_legal') : $t('lastname')"
                        label-for="last_name"
                        style="margin-bottom: 26px;"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('lastname')"
                          vid="last_name"
                          rules="required|notPersian"
                        >
                          <b-form-input
                            id="last_name"
                            v-model="form.last_name"
                            :name="$t('lastname')"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('lastname')"
                            @keypress="AlphaNumericAndEspecialCharacters($event)"
                          />
                          <small class="text-danger position-absolute">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-form-group
                    :label="$t('email')"
                    label-for="email"
                    style="margin-bottom: 26px;"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('email')"
                      vid="email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model.trim="form.email"
                        :name="$t('email')"
                        :state="errors.length > 0 ? false : null"
                        placeholder="john@example.com"
                        @keypress="AlphaNumericAndEspecialCharacters($event)"
                      />
                      <small class="text-danger position-absolute">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    label-for="register-password"
                    :label="$t('password')"
                    class="pass-comp"
                  >
                    <password
                      v-model="form.password"
                      :toggle="true"
                      :badge="false"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      :class="$store.state.appConfig.layout.skin == 'dark' ? 'dark_password' : ''"
                      class="password_copm"
                      @score="showScorePassword"
                      @feedback="showFeedbackPassword"
                    />
                    <span
                      v-if="passwordScore <= 2 && form.password"
                      class="text-danger"
                    >
                      {{ $t('passwordIsTooWeak') }}
                    </span>
                  </b-form-group>

                  <b-form-group
                    label-for="password_confirmation"
                    :label="$t('password_confirmation')"
                    style="margin-bottom: 32px;"
                    class="pass-comp"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('password_confirmation')"
                      vid="password_confirmation"
                      rules="required"
                    >
                      <password
                        v-model="form.password_confirmation"
                        :toggle="true"
                        :badge="false"
                        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                        :class="$store.state.appConfig.layout.skin == 'dark' ? 'dark_password' : ''"
                        class="password_copm"
                        :show-strength-meter="false"
                      />
                      <small
                        v-if="errors[0]"
                        class="position-absolute text-danger"
                      >
                        {{ errors[0] }}
                      </small>
                      <small
                        v-if="!errors[0] && passwordConfirmation"
                        class="position-absolute text-danger"
                      >
                        {{ $t('password_not_match') }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label-for="mobile-country-code"
                    :label="$t('mobile_country_code')"
                  >
                    <validation-provider
                      #default="{ errors }"
                      ref="mobile_country_code"
                      :name="$t('mobile_country_code')"
                      vid="mobile_country_code"
                      rules="required"
                    >
                      <v-select
                        id="mobile-country-code"
                        v-model="form.mobile_country_code"
                        label="precode"
                        :options="pre_country_code_lists"
                        :filter-by="myFilter"
                        :placeholder="$t('mobile_country_code')"
                        autocomplete="nope"
                        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                        @input="changeCountryCode"
                      >
                        <template
                          slot="option"
                          slot-scope="option"
                          class="w-100 text-left"
                        >
                          <div
                            dir="ltr"
                            class="w-100 d-flex "
                          >
                            <img
                              :src="option.image"
                              class="language-image"
                            >
                            &nbsp;
                            <span class="select d-block">+{{ option.precode }}</span>
                            &nbsp;
                            <span class=" d-block"> ({{ option.name }})</span>
                          </div>
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    label-for="mobile_number"
                    :label="$t('mobile_number_without_zero')"
                  >
                    <validation-provider
                      #default="{ errors }"
                      ref="mobile"
                      :name="$t('mobile')"
                      vid="mobile"
                      rules="required|noZeroStater"
                    >
                      <div class="d-md-flex ">
                        <b-form-input
                          id="mobile"
                          v-model="form.mobile"
                          :name="$t('mobile_number')"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('mobile_number')"
                          @input="changeMobile"
                          @keypress="AlphaNumericAndEspecialCharacters($event)"
                        />
                        <div class="mx-1" />
                        <b-button
                          :disabled="otpLoading || otpResendTimer > 0"
                          variant="primary"
                          block
                          type="button"
                          @click="handleButtonClick"
                        >
                          <span v-if="otpResendTimer > 0">{{ convertSecondsToMinutes(otpResendTimer) }}</span>
                          &nbsp;
                          <span v-if="!otpLoading">{{ $t('submitVerficationCode') }}</span>
                          <span v-if="otpLoading"> <b-spinner
                            small
                            type="grow"
                          />&nbsp;{{ $t('submitVerficationCode')
                          }}</span>

                        </b-button>
                      </div>
                      <p
                        style="border-bottom: 1px dashed;width: max-content; margin-top: 10px;cursor: pointer; color: aqua;"
                        @click="senderModal=true"
                      >
                        {{ $t('send-otp-ways') }}
                      </p>
                      <small class="text-danger position-absolute">{{ $t(errors[0]) }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    class="mb-2"
                    label-for="verification_code"
                    :label="$t('verification_code')"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('verification_code')"
                      vid="verification_code"
                      rules="required"
                    >
                      <b-form-input
                        v-model="form.otp_code"
                        :name="$t('verification_code')"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('verification_code')"
                        @keypress="AlphaNumericAndEspecialCharacters($event)"
                      />
                      <small class="text-danger position-absolute">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group>
                    <vue-recaptcha
                      ref="recaptcha"
                      :sitekey="sitekey"
                      :load-recaptcha-script="true"
                      :theme="$store.state.appConfig.layout.skin == 'dark' ? 'dark' : ''"
                      @expired="onCaptchaExpired"
                      @verify="onVerify"
                    />
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      id="register-privacy-policy"
                      v-model="status"
                      name="checkbox-1"
                    >
                      {{ $t('Agreement_register_page.i_agree') }}
                      <b-link
                        :to="{ name: 'agreement-register' }"
                        target="_blank"
                      >
                        {{ $t('Agreement_register_page.terms') }}
                      </b-link>
                    </b-form-checkbox>
                  </b-form-group>

                  <b-button
                    v-if="!pending"
                    variant="primary"
                    block
                    type="submit"
                    :disabled="invalid || passwordScore < 3 || passwordConfirmation || !status || !form.recaptcha"
                  >
                    {{ $t('sign_up') }}
                  </b-button>
                  <b-button
                    v-if="pending"
                    variant="primary"
                    block
                    disabled
                  >
                    <b-spinner
                      small
                      type="grow"
                    />
                  </b-button>
                </div>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <span>{{ $t('have_account') }}</span>
              <b-link :to="{ name: 'login' }">
                <span>&nbsp;{{ $t('signin_instead') }}</span>
              </b-link>
            </p>
          </div>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
    <div>
      <b-modal
        id="modal-center"
        v-model="senderModal"
        hide-footer
        centered
      >
        <p class="my-1">
          {{ $t('send_in_other_ways') }}
        </p>
        <div class="text-center my-2">
          <b-button
            v-b-tooltip.hover="Telegram"
            variant="primary"
            class="mr-1"
            @click="sendVerificationCode('telegram')"
          >
            <b-icon
              icon="telegram"
              aria-hidden="true"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover="WhatsApp"
            variant="success"
            class="mr-1"
            @click="sendVerificationCode('whatsapp')"
          >
            <b-icon
              icon="whatsapp"
              aria-hidden="true"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover="SMS"
            variant="dark"
            class=""
            @click="sendVerificationCode('sms')"
          >
            <b-icon
              icon="chat-text"
              aria-hidden="true"
            />
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BCardTitle,
  BCardText,
  BSpinner,
  BIconTelegram,
  BIconWhatsapp,
  BIconChatText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  required, email, notPersian, noZeroStater,
} from '@validations'
import VueRecaptcha from 'vue-recaptcha'
import { mapActions } from 'vuex'
import Password from 'vue-password-strength-meter'
import moment from 'moment'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import store from '@/store'
import { alphanumeric } from '@/mixins/alphanumeric'
import AuthApis from '@/services/apis/auth'
import { changeLang } from '@/auth/utils'
import { getCookieByName, convertSecondsToMinutes } from '@/utils'
import logo from '@/views/components/logo.vue'
import FormApis from '@/services/apis/form'

const auth = new AuthApis()
const formData = new FormApis()

export default {
  components: {
    DarkToggler,
    logo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BSpinner,
    VueRecaptcha,
    vSelect,
    Password,
    BIconTelegram,
    BIconWhatsapp,
    BIconChatText,
  },
  mixins: [alphanumeric],
  data() {
    return {
      senderModal: false,
      checked1: false,
      checked2: false,
      registerStep: 1,
      isLegal: false,
      select: null,
      status: '',
      otpResendTimer: 0,
      isFirstOtp: true,
      moment,
      convertSecondsToMinutes,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        recaptchaToken: '',
        recaptcha: null,
        mobile_country_code: '',
        mobile: '',
        otp_code: '',
      },
      otpLoading: false,
      pre_country_code_lists: [],
      myFilter: (option, label, search) => {
        const temp = search.toLowerCase()
        return option.name.toLowerCase().indexOf(temp) > -1
          || option.precode.toString().toLowerCase().indexOf(temp) > -1
      },
      language: this.$route.params.lang,

      sideImg: require('@/assets/images/pages/register-v2.svg'),
      required,
      email,
      notPersian,
      noZeroStater,
      passwordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      pending: false,
      passwordError: '',
      passwordScore: 0,
    }
  },

  computed: {
    options() {
      return this.$i18n.locales
      // return [
      //   {
      //     title: 'English',
      //     locale: 'en',
      //     url: require('@/assets/images/flags/en.png'),
      //   },
      //   {
      //     title: 'Farsi',
      //     locale: 'fa',
      //     url: require('@/assets/images/flags/IR.png'),
      //   },
      // ]
    },
    mediaType() {
      return (process.env.VUE_APP_LOGIN_MEDIA_TYPE)
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
    mediaUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = process.env.VUE_APP_LOGIN_MEDIA
        return this.sideImg
      }
      return process.env.VUE_APP_LOGIN_MEDIA
    },
    passwordConfirmation() {
      return String(this.form.password) !== String(this.form.password_confirmation)
    },
    sitekey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY
    },
    mobileAndPreCode() {
      return this.form.mobile + this.form.mobile_country_code.precode
    },
  },
  watch: {
    mobileAndPreCode() {
      this.otpResendTimer = 0
    },
  },
  beforeCreate() {
    const token = localStorage.getItem('token')
    if (token) {
      this.$router.push({ name: 'dashboard' })
    }
  },
  beforeMount() {
    this.language = this.options.find(item => item.locale === this.$route.params.lang)
    formData.allproperties().then(res => {
      const result = res.data.results

      this.pre_country_code_lists = result.countries.map(item => ({
        // eslint-disable-next-line global-require, import/no-unresolved, import/no-dynamic-require
        precode: item.precode, id: item.id, name: item.name, image: require(`@/assets/images/flags/${item.abbreviation}.png`),
      }))
    })
  },
  methods: {
    ...mapActions(['register']),
    changeLang(val) {
      changeLang(val.locale)
    },
    async submit() {
      const success = await this.$refs.registerForm.validate()

      // TODO : here call a api and send referralLink to server
      // BUG : cookie in first load not updated, i think this is a bug.
      // this.$cookies.get('referral')

      if (success) {
        this.pending = true
        const lang = [{ language: this.language.locale }]
        const data = JSON.parse(JSON.stringify({ ...this.form, legal_status: this.isLegal ? 'legal_entity' : 'individual', ...(getCookieByName('refID') && { referral: getCookieByName('refID') }) }))
        delete data.mobile_country_code
        auth.register(Object.assign(lang[0], data)).then(response => {
          if (response.status === 201 || response.status === 200) {
            window.dataLayer.push({
              event: 'sign_up',
            })
            this.$swal({
              title: response.data?.message,
              icon: 'success',
              timer: 4000,
              showConfirmButton: false,
            })
            localStorage.setItem('token', response.data.results.token)
            localStorage.setItem('authenticated', true)
            this.$router.push({ name: 'register-success' })
          }
        })
          .finally(() => {
            this.pending = false
            this.onCaptchaExpired()
          })
      }
    },
    onVerify(response) {
      this.form.recaptcha = response
    },
    async onCaptchaExpired() {
      this.$refs.recaptcha.reset()
      this.form.recaptcha = null
    },

    showScorePassword(score) {
      this.passwordScore = score
    },
    showFeedbackPassword({ warning }) {
      this.passwordError = warning
    },
    handleButtonClick() {
      if (this.isFirstOtp) {
        this.sendVerificationCode()
      } else {
        this.senderModal = true
      }
    },
    changeCountryCode() {
      this.isFirstOtp = true
    },
    changeMobile() {
      this.isFirstOtp = true
    },
    async sendVerificationCode(sender) {
      const countryCodeValid = await this.$refs.mobile_country_code.validate()
      const mobile = await this.$refs.mobile.validate()
      if (countryCodeValid.valid && mobile.valid) {
        this.otpLoading = true
        auth.sendOtp({ mobile: this.form.mobile, mobile_country_code: this.form.mobile_country_code.precode, ...(sender && { sender }) }).then(response => {
          this.otpResendTimer = response.data.results.remained_time
          this.countDownResetOtp()
          this.$swal({
            title: response.data?.message,
            icon: 'success',
            timer: 4000,
            showConfirmButton: false,
          })
          this.isFirstOtp = false
          this.senderModal = false
        }).catch(err => {
          this.otpResendTimer = err.data.results.remained_time
          this.countDownResetOtp()
          this.isFirstOtp = false
        }).finally(() => {
          this.otpLoading = false
          this.isFirstOtp = false
        })
      }
    },
    countDownResetOtp() {
      setTimeout(() => {
        this.otpResendTimer -= 1
        if (this.otpResendTimer > 0) {
          this.countDownResetOtp()
        }
      }, 1000)
    },
    setLegal(val) {
      this.registerStep = 2
      this.isLegal = val
    },
    changeStep(val) {
      this.registerStep = val
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.language-image {
  width: 22px;
  aspect-ratio: auto 22 / 14;
  height: 14px;
}

.pass-comp .Password {
  max-width: unset;
}

.dark_password .Password__field {
  background-color: #283046;
  color: #b4b7bd;
}

.password_copm .Password__field:focus {
  outline: 1px solid $primary;
}
</style>
<style scoped>
::v-deep div[style*="left: 85.8px"] {
  left: 0 !important;
}
</style>
